<template>
  <div>
    <div id="destinations" class="container">
      <b-row class="car">
        <b-col>
          <b-carousel
            id="carousel-2"
            :interval="2000"
            background="transparent"
            img-width="524"
            img-height="480"
            no-animation
          >
            <a :href="tourPage.acf.banner_advert_one_link">
              <b-carousel-slide
                :img-src="tourPage.acf.banner_advert_one_image"
              ></b-carousel-slide>
            </a>
            <a :href="tourPage.acf.banner_advert_two_link">
              <b-carousel-slide
                :img-src="tourPage.acf.banner_advert_two_image"
              ></b-carousel-slide>
            </a>
            <a :href="tourPage.acf.banner_advert_three_link">
              <b-carousel-slide
                :img-src="tourPage.acf.banner_advert_three_image"
              ></b-carousel-slide>
            </a>
            <a :href="tourPage.acf.banner_advert_four_link">
              <b-carousel-slide
                :img-src="tourPage.acf.banner_advert_four_image"
              ></b-carousel-slide>
            </a>
          </b-carousel>
        </b-col>
      </b-row>
      <b-carousel
        id="carousel-1"
        :interval="5000"
        fade
        background="#f1f1f1"
        img-width="1024"
        img-height="350"
      >
        <template v-for="(slides, index) in slider">
          <b-carousel-slide
            :key="index"
            class="backImg"
            :img-src="slides.acf.image"
          >
            <h2 class="cap">
              <span v-html="slides.title.rendered"></span>
            </h2>
          </b-carousel-slide>
        </template>
      </b-carousel>
      <div class="gradHome"></div>
      <b-container>
        <b-row>
          <b-col>
            <template v-if="tourPage.acf.partnerstrip.length > 0">
              <template v-if='isMobile(true)'>
                <b-img class="mobileLogo" src="https://assets.asiantour.com/asian-tour/2023/06/AsainTour_Dev_Logos_Mobile.png">
                </b-img>
              </template>
              <template v-else>
                <b-img
                  class="bar"
                  :src="tourPage.acf.partnerstrip"
                ></b-img>
              </template>
            </template>
            <template v-else> </template>
          </b-col>
        </b-row>
        <b-row class="info">
          <b-col>
            <h3 class="aboutAsianDest">
              {{ tourPage.acf.top_title }}
            </h3>
            <span
              v-html="tourPage.acf.top_paragaraph"
              class="asianTourDest-descript"
            ></span>
          </b-col>
        </b-row>
        <div>
            <Post
              :posts="config.VUE_APP_WP_ASIAN_TOUR_API_URL + dest"
              :develop="'dest'"
              :config="config"
            />
        </div>
        <b-row class="bPad">
          <b-col lg='6' md='12' v-for="(dest, index) in tourDes" :key="index">
            <b-card
              :img-src="dest.acf.main_image"
              img-alt="Image"
              img-top
              tag="article"
              footer-tag="footer"
            >
              <b-row>
                <b-col>
                  <b-link :to="'/destinations/' + dest.slug" class="destLink">
                    <h5 class="pt" v-html="dest.acf.title"></h5>
                  </b-link>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span class="exre" v-html="dest.acf.paragraph"></span>
                </b-col>
              </b-row>
              <b-row class="cardRone">
                <b-col>
                  <b-row class="socialLink">
                    <b-col v-if="dest.acf.facebook_link !== ''" class="col-md-2">
                      <b-nav-item :href="dest.acf.facebook_link">
                        <font-awesome-icon
                          class="icon"
                          :icon="['fab', 'facebook-square']"
                        />
                      </b-nav-item>
                    </b-col>
                    <b-col v-if="dest.acf.twitter_link !== ''" class="col-md-2">
                      <b-nav-item :href="dest.acf.twitter_link">
                        <font-awesome-icon
                          class="icon"
                          :icon="['fab', 'twitter']"
                        />
                      </b-nav-item>
                    </b-col>
                    <b-col v-if="dest.acf.instagram_link !== ''" class="col-md-2">
                      <b-nav-item :href="dest.acf.instagram_link">
                        <font-awesome-icon
                          class="icon"
                          :icon="['fab', 'instagram']"
                        />
                      </b-nav-item>
                    </b-col>
                    <b-col
                      v-if="dest.acf.find_out_more_link !== ''"
                      class="col-md-2"
                    >
                      <b-nav-item :href="dest.acf.find_out_more_link">
                        <font-awesome-icon class="icon" :icon="['fa', 'globe']" />
                      </b-nav-item>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-img class="distLogo" :src="dest.acf.main_logo"></b-img>
                </b-col>
              </b-row>
              <b-link :to="'/destinations/' + dest.slug" class="destLink">
                <button class="findOutMore">
                  Find Out More Information
                </button>
              </b-link>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="gradHome"></div>
    <div class="greyBK">
      <b-container>
        <b-row class="destPartSections">
          <b-col>
            <h3 class="destPartners">
              <b-nav-item to="/destinations-partners">
                Asian Tour Destinations Partners
              </b-nav-item>
            </h3>
            <div class="hrLine">
            </div>
          </b-col>
        </b-row>
        <b-row class="DestinationsPartner-logo">
          <b-col
            style="margin:auto;"
            lg="3"
            md="6"
            cols="12"
            v-for="(spon, index) in desPart"
            :key="index"
          >
            <a :href="spon.acf.dest_partner_link">
              <b-img class="bImg" :src="spon.acf.dest_partner_image"></b-img>
            </a>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Post from "@/components/posts.vue";
import axios from "axios";
export default {
  name: "destination",
  props: ["config"],
  components: { Post },
  data() {
    return {
      slider: [],
      tourDes: [],
      tourPage: [],
      dest: "wp/v2/posts?categories=77&randomadd=" + new Date().getTime(),
      desPart: [],
    };
  },
  methods: {
    isMobile() {
      if( screen.width <= 500 ) {
        return true;
      }
      else {
        return false;
      }
    },
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_WP_ASIAN_TOUR_API_URL + "wp/v2/pages/9898?_embed&per_page=20"
      )
      .then((response) => {
        this.tourPage = response.data;
        return axios.get(
          process.env.VUE_APP_WP_ASIAN_TOUR_API_URL +
            "wp/v2/tourdestinations?_embed&per_page=50"
        );
      })
      .then((response) => {
        this.tourDes = response.data;
        return axios.get(
          process.env.VUE_APP_WP_ASIAN_TOUR_API_URL +
            "wp/v2/destinationsslider?_embed&per_page=50"
        );
      })
      .then((response) => {
        this.slider = response.data;
        return axios.get(
          process.env.VUE_APP_WP_ASIAN_TOUR_API_URL +
            "wp/v2/destinationspartners?_embed&per_page=50&randomadd=" +
                new Date().getTime()
        );
      })
      .then((response) => {
        this.desPart = response.data;
      });
  },
};
</script>

<style scoped>
::v-deep .newsImage {
  width: 100%;
  max-height: 230px;
  padding-right: 5px;
  min-height: 230px;
}
.hrLine {
  height: 2px;
  background-color: #EBEBEB;
  margin-top: 30px;
  margin-bottom: 30px;
}
.greyBK {
  background-color: #F5F5F5;
  padding-top: 10px;
  padding-bottom: 60px;
}
.findOutMore {
  font-size: 14px;
  /* padding-bottom: 20px; */
  color: #FFFFFF!important;
  font-weight: bold;
  background-color: #98C556;
  text-align: center;
  padding: 10px;
  width: 100%;
  height: 59px;
  border: none;
}
.findOutMore:hover {
  font-size: 14px;
  /* padding-bottom: 20px; */
  color: #FFFFFF!important;
  font-weight: bold;
  background-color: #BCDD8D;
  text-align: center;
  padding: 10px;
  width: 100%;
  height: 59px;
  border: none;
}
::v-deep .destPartners > li > a{
  color: #98c656!important;
  text-align: center;
  font-size: 17px!important;
}
a.destLink > .pt:hover {
  color: #41a2f6;
}
a.destLink:hover {
  text-decoration: none;
}
.mobileLogo {
  width: 100%;
  margin-top: -15px;
}
::v-deep .DestinationsPartner-logo {
  margin-top: -50px;
}
::v-deep span.asianTourDest-descript > p {
  font-size: 14px;
  color: #707070;
  font-weight: 400;
  text-align: left!important;
}
::v-deep .socialLink {
  /* float: right; */
  padding: 0;
}
::v-deep .nav-link {
  display: block;
  padding: 0;
}
#destinations {
  /* background-color: #f1f1f1; */
}
#carousel-2 {
  /* padding: 0 20%; */
}
.cap {
  position: absolute;
  right: 62%;
  bottom: -28px;
  left: -22%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  background-color: rgba(10, 63, 127, 1)!important;
  width: 300px;
}
.bar {
  margin-top: 20px;
  width: 100%;
  z-index: 200;
}
.car {
  padding: 50px 0;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 0;
  opacity: 1;
}
.info {
  text-align: center;
  padding-bottom: 4%;
}
.aboutAsianDest {
  color: #0A3F7F;
  margin-bottom: 2em;
  margin-top: 2em;
  text-align: left;
  font-size: 20px!important;
}
.card-img-top {
  width: 100%;
  height: 306px;
  /* min-height: 183px; */
  border-radius: 0px 0px 0 0 !important;
  /* max-height: 200px; */
}
.card-body {
  min-height: 247px;
  padding-top: 10px!important;
  padding: 0;
}
.card-footer {
  border-radius: 0 0 0px 0px !important;
  background-color: #133f7b;
  color: white;
  text-align: center;
}
.card {
  background-color: transparent;
  border: 0;
  padding-bottom: 12%;
  padding-top: 2%;
  /* height: 800px; */
}
::v-deep .exre {
  display: block;
  color: #707070;
  font-size: 14px;
  font-weight: 300;
  height: 130px;
}
.pt {
  color: #133f7a;
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 15px;
}
.newsTitle {
  color: #A8B7C9;
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.distLogo {
  max-height: 60px;
  float: right;
}
.cardRone {
  padding-bottom: 5%;
}
::v-deep li {
  list-style-type: none;
}
::v-deep li > a > span {
  color: white;
  font-size: 12pt;
  position: relative;
  top: -2px;
}
::v-deep svg {
  color: #133f7a;
}
.bImg {
  width: 80%;
  padding: 5% 0;
}
.bPad {
  padding-bottom: 50px;
  margin-top: 5em;
}
.destPartSections  {
  padding-bottom: 50px;
  margin-top: 2em;
}
@media only screen and (max-width: 990px) {
  .mobileLogo {
    margin-top: 0;
  }
  .cap {
    position: absolute;
    right: 62%;
    bottom: -28px;
    left: -22%;
    z-index: 10;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    font-size: 10px;
    text-align: center;
    background-color: rgba(10, 63, 127, 1)!important;
    width: 300px;
  }
}
.gradHome {
  background-color: #98C556;
  background-image: linear-gradient(to right, #98C556 , #BCDD8D);
  height: 10px;
  /* width: 96%;
  position: absolute; */
  top: 190px;
}
@media only screen and (max-width: 500px) {
  .bImg {
    width: 23%;
    padding: 5% 0;
  }
  .col-md-6.col-lg-3.col-12 {
    margin: auto;
    display: block;
    text-align: center;
  }
  .card-img-top {
    width: 100%;
    height: 230px;
    border-radius: 0px 0px 0 0 !important;
  }
  .distLogo {
    max-height: 60px;
    float: right;
  }
}
</style>
